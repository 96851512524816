import { useState } from "react";
import { District, PrioritizedCustomer } from "../models/PrioritizedCustomerModels";
import Select, { MultiValue } from 'react-select';
import Moment from 'moment';
import { putPrioritizedCustomers } from "../services/PrioritizedCustomerService";

interface DisplaySettings {
    creatingPrioritizedCustomer: boolean
    showCreatingPrioritizedCustomerError: boolean
    showDateTimeValidationError: boolean
    showLivingAreaValidationError: boolean
    showMinNrRoomsValidationError: boolean
    showMaxNrRoomsValidationError: boolean
    showMaxRentValidationError: boolean
    showCustomerIdValidationError: boolean
}

const CreatePrioritizedCustomerComponent = ({ districts, onCancel, onSave }: { districts: District[], onCancel: () => void, onSave: () => void }) => {
    const numberPattern = /^\d*$/;

    const [currentPrioritizedCustomer, setPrioritizedCustomer] = useState<PrioritizedCustomer>({ customerId: '', districts: [] as District[], freeText: '', livingArea: 0, maxNrRooms: 0, maxRent: 0, minNrRooms: 0, queueStart: new Date(), queueStartString: '' } as PrioritizedCustomer);
    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({ creatingPrioritizedCustomer: false, showCreatingPrioritizedCustomerError: false, showDateTimeValidationError: true } as DisplaySettings);

    const ResetPrioritizedCustomer = () => {
        setPrioritizedCustomer({
            customerId: '',
            districts: [] as District[],
            freeText: '',
            livingArea: 0,
            maxNrRooms: 0,
            maxRent: 0,
            minNrRooms: 0,
            queueStart: new Date(),
            queueStartString: new Date().toString(),
        } as PrioritizedCustomer)
    };

    function SavePrioritizedCustomer() {
        setDisplaySettings((prevState) => ({ ...prevState, creatingPrioritizedCustomer: true, showCreatingPrioritizedCustomerError: false }));

        putPrioritizedCustomers(currentPrioritizedCustomer).then(response => {
            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showCreatingPrioritizedCustomerError: true }));
            }
            else {
                ResetPrioritizedCustomer();
                onSave();
            }
        }).finally(() => {
            setDisplaySettings((prevState) => ({ ...prevState, creatingPrioritizedCustomer: false }));
        })
    }

    const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setDisplaySettings((prevState) => ({ ...prevState, showDateTimeValidationError: false }));

        const pattern = /^\d{4}-\d{2}-\d{2}$/;
        var date = Moment(event.target.value, 'YYYY-MM-DD');

        if (!date.isValid() || !pattern.test(event.target.value)) {
            setDisplaySettings((prevState) => ({ ...prevState, showDateTimeValidationError: true }));
        }
        else {
            setPrioritizedCustomer((prevState) => ({ ...prevState, queueStart: new Date(event.target.value) }))
        }

        setPrioritizedCustomer((prevState) => ({ ...prevState, queueStartString: event.target.value }))
    };

    const handleLivingAreaChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value === '') {
            setPrioritizedCustomer((prevState) => ({ ...prevState, livingArea: 0 }))
        }
        else if (numberPattern.test(event.target.value)) {
            setPrioritizedCustomer((prevState) => ({ ...prevState, livingArea: parseInt(event.target.value) }))
        }
    };

    const handleMinNrRoomsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value === '') {
            setPrioritizedCustomer((prevState) => ({ ...prevState, minNrRooms: 0 }))
        }
        else if (numberPattern.test(event.target.value)) {
            setPrioritizedCustomer((prevState) => ({ ...prevState, minNrRooms: parseInt(event.target.value) }))
        }
    };

    const handleMaxNrRoomsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value === '') {
            setPrioritizedCustomer((prevState) => ({ ...prevState, maxNrRooms: 0 }))
        }
        else if (numberPattern.test(event.target.value)) {
            setPrioritizedCustomer((prevState) => ({ ...prevState, maxNrRooms: parseInt(event.target.value) }))
        }
    };

    const handleMaxRentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value === '') {
            setPrioritizedCustomer((prevState) => ({ ...prevState, maxRent: 0 }))
        }
        else if (numberPattern.test(event.target.value)) {
            setPrioritizedCustomer((prevState) => ({ ...prevState, maxRent: parseInt(event.target.value) }))
        }
    };

    const handleCustomerIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setDisplaySettings((prevState) => ({ ...prevState, showCustomerIdValidationError: false }));

        if (event.target.value === '') {
            setDisplaySettings((prevState) => ({ ...prevState, showCustomerIdValidationError: true }));
        }

        setPrioritizedCustomer((prevState) => ({ ...prevState, customerId: event.target.value }))
    }

    const handleDistrictChange = (value: MultiValue<District>) => {
        console.log("Value", value as District[]);
        setPrioritizedCustomer((prevState) => ({ ...prevState, districts: value as District[] }))
    }

    return (<>
        <div className="card mb-5">
            <div className="card-body">

                <div className="row">
                    <div className="col-2">
                        <h5 className="card-title">Kundnummer:</h5>
                    </div>
                    <div className="col-6">
                        <div className="col-5 mb-1"><input type="text" className={displaySettings.showCustomerIdValidationError ? 'border-danger form-control form-control-sm' : 'form-control form-control-sm'} value={currentPrioritizedCustomer?.customerId} onChange={handleCustomerIdChange} disabled={displaySettings.creatingPrioritizedCustomer} /></div>
                    </div>
                    <div className="col-4 text-end">
                        {displaySettings.creatingPrioritizedCustomer ?
                            <></> :
                            <>
                                <span role="button"><i className="fa-solid fa-xmark ms-2" onClick={onCancel}></i></span>
                            </>
                        }
                    </div>
                </div>
                <div className="mb-2">
                    <Select
                        isMulti
                        name="colors"
                        value={currentPrioritizedCustomer.districts}
                        options={districts}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        getOptionLabel={(option) => option.district}
                        getOptionValue={(option) => option.district}
                        onChange={handleDistrictChange}
                        isDisabled={displaySettings.creatingPrioritizedCustomer}
                    />
                </div>

                <div className="row">
                    <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="row">
                            <div className="col-4">Rum:</div>
                            <div className="col-2 mb-1"><input type="text" className={displaySettings.showMinNrRoomsValidationError ? 'border-danger form-control form-control-sm' : 'form-control form-control-sm'} value={currentPrioritizedCustomer?.minNrRooms} onChange={handleMinNrRoomsChange} disabled={displaySettings.creatingPrioritizedCustomer} /></div>
                            <div className="col-1 pt-1">-</div>
                            <div className="col-2"><input type="text" className={displaySettings.showMaxNrRoomsValidationError ? 'border-danger form-control form-control-sm' : 'form-control form-control-sm'} value={currentPrioritizedCustomer?.maxNrRooms} onChange={handleMaxNrRoomsChange} disabled={displaySettings.creatingPrioritizedCustomer} /></div>
                        </div>

                        <div className="row">
                            <div className="col-4">Min area:</div>
                            <div className="col-5 mb-1"><input type="text" className={displaySettings.showLivingAreaValidationError ? 'border-danger form-control form-control-sm' : 'form-control form-control-sm'} value={currentPrioritizedCustomer?.livingArea} onChange={handleLivingAreaChange} disabled={displaySettings.creatingPrioritizedCustomer} /></div>
                        </div>

                        <div className="row">
                            <div className="col-4">Max hyra:</div>
                            <div className="col-5 mb-1"><input type="text" className={displaySettings.showMaxRentValidationError ? 'border-danger form-control form-control-sm' : 'form-control form-control-sm'} value={currentPrioritizedCustomer?.maxRent} onChange={handleMaxRentChange} disabled={displaySettings.creatingPrioritizedCustomer} /></div>
                        </div>

                        <div className="row">
                            <div className="col-4">Registreringsdatum:</div>
                            <div className="col-5 mb-1"><input type="text" className={displaySettings.showDateTimeValidationError ? 'border-danger form-control form-control-sm' : 'form-control form-control-sm'} value={currentPrioritizedCustomer?.queueStartString} onChange={handleDateTimeChange} disabled={displaySettings.creatingPrioritizedCustomer} placeholder="YYYY-MM-DD" /></div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                        <textarea className="form-control form-control-sm" rows={5} value={currentPrioritizedCustomer.freeText} onChange={(e) => setPrioritizedCustomer((prevState) => ({ ...prevState, freeText: e.target.value }))} disabled={displaySettings.creatingPrioritizedCustomer} />
                    </div>
                    {displaySettings.showCreatingPrioritizedCustomerError ?
                        <div className="col-12 text-end text-danger">
                            Något gick fel när bevakningskund sparades, försök gärna igen.
                        </div> :
                        <></>
                    }

                    <div className="col-12 text-end">
                        <button className="btn btn-primary btn-sm float-end d-flex" disabled={displaySettings.showDateTimeValidationError || displaySettings.creatingPrioritizedCustomer || displaySettings.showCustomerIdValidationError} onClick={SavePrioritizedCustomer}>
                            {displaySettings.creatingPrioritizedCustomer ?
                                <>
                                    <div className="spinner-container pe-2">
                                        <div className="spinner-border spinner-border-sm" role="status"><span className="visually-hidden"></span> </div>
                                    </div> Skapar bevakningskund
                                </> :
                                <>Skapa bevakningskund</>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default CreatePrioritizedCustomerComponent;