import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { msalInstance, scopes } from '../authentication/msalConfig';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API
})

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
}

const onRequest = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    const account = msalInstance.getAllAccounts()[0];

    if (account) {
        const accessTokenResponse = await msalInstance.acquireTokenSilent({
            scopes: scopes,
            account: account
        });

        if (accessTokenResponse) {
            const accessToken = accessTokenResponse.accessToken;

            if (config.headers && accessToken) {
                config.headers['Authorization'] = 'Bearer ' + accessToken;
            }
        }
    }

    return config;
}

instance.interceptors.request.use(onRequest, onRequestError);

export default instance;