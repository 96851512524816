import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { District, PrioritizedCustomer } from '../models/PrioritizedCustomerModels';
import { getDistricts, getPrioritizedCustomers } from '../services/PrioritizedCustomerService';
import CreatePrioritizedCustomerComponent from './CreatePrioritizedCustomerComponent';
import PrioritizedCustomerComponent from './PrioritizedCustomerComponent';

interface DisplaySettings {
    showGetPrioritizedCustomerSpinner: boolean
    showErrorInformation: boolean
    showCreatePrioritizedCustomer: boolean
    isAuthenticated: boolean
    hasCorrectRole: boolean
}

const PrioritizedCustomersComponent = () => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();

    const [prioritizedCustomers, setPrioritizedCustomers] = useState<PrioritizedCustomer[]>([] as PrioritizedCustomer[]);
    const [districts, setDistricts] = useState<District[]>([] as District[]);

    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({ showGetPrioritizedCustomerSpinner: false, showCreatePrioritizedCustomer: false, isAuthenticated: false } as DisplaySettings);

    useEffect(() => {
        if (isAuthenticated) {

            setDisplaySettings((prevState) => ({ ...prevState, isAuthenticated: true }));

            if (accounts.length > 0 && accounts[0].idTokenClaims?.roles && accounts[0].idTokenClaims?.roles?.length > 0 && accounts[0].idTokenClaims.roles.includes(process.env.REACT_APP_REQUIRED_ROLE as string))
            {
                setDisplaySettings((prevState) => ({ ...prevState, hasCorrectRole: true }));
                PopulateDistricts();
                PopulatePrioritizedCustomers();
            }
        }
    }, [isAuthenticated]);


    const PopulatePrioritizedCustomers = () => {
        setDisplaySettings((prevState) => ({ ...prevState, showGetPrioritizedCustomerSpinner: true, showErrorInformation: false }));

        getPrioritizedCustomers().then((response) => {
            setPrioritizedCustomers(response.prioritizedCustomers);

            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showErrorInformation: true }));
            }
        }).finally(() => {
            setDisplaySettings((prevState) => ({ ...prevState, showGetPrioritizedCustomerSpinner: false }));
        });
    }

    const PopulateDistricts = () => {
        getDistricts().then((response) => {
            setDistricts(response.districts);
        })
    }

    const handleDelete = (customerId : string) => {
        setPrioritizedCustomers(prevItems => prevItems.filter(item => item.customerId !== customerId));
    }

    const handleToggleCreate = () => {
        if (displaySettings.showCreatePrioritizedCustomer) {
            setDisplaySettings((prevState) => ({ ...prevState, showCreatePrioritizedCustomer: false }));
        }
        else {
            setDisplaySettings((prevState) => ({ ...prevState, showCreatePrioritizedCustomer: true }));
        }
    }

    const sortByCustomerId = () => {
        const sortedCustomer = [...prioritizedCustomers].sort((a, b) => String(a.customerId).padStart(10, '0').localeCompare(String(b.customerId).padStart(10, '0')));

        setPrioritizedCustomers(sortedCustomer);
    }

    const sortByQueue = () => {
        const sortedCustomer = [...prioritizedCustomers].sort((a, b) => a.queueStart.getTime() - b.queueStart.getTime());

        setPrioritizedCustomers(sortedCustomer);
    }

    const handleCreated = () => {
        handleToggleCreate();
        PopulatePrioritizedCustomers();
    }

    const handleCreateCancel = () => {
        handleToggleCreate();
    }

    if (displaySettings.showGetPrioritizedCustomerSpinner) {
        return (
            <div className="d-flex text-secondary">
                <div className="spinner-container ">
                    <div className="spinner-border spinner-border-sm" role="status"><span className="visually-hidden"></span> </div>
                </div>
                <div className="ps-2 fw-semibold">Hämtar prioriterade kunder.</div>
            </div>);
    }
    if (displaySettings.isAuthenticated && displaySettings.hasCorrectRole) {
        return (
            <>
                {displaySettings.showCreatePrioritizedCustomer ?
                    <div>
                        <CreatePrioritizedCustomerComponent districts={districts} onSave={handleCreated} onCancel={handleCreateCancel}></CreatePrioritizedCustomerComponent>
                    </div> :
                    <div className="d-flex justify-content-between">
                        <div className="">
                            Antal bevakningskunder: <b>{prioritizedCustomers.length}</b>, 
                            Sortera efter:
                            <button className="btn btn-outline-secondary btn-sm ms-2" onClick={sortByCustomerId}>Kundnummer</button>
                            <button className="btn btn-outline-secondary btn-sm ms-2" onClick={sortByQueue}>Kö</button>
                        </div>
                        <div className="text-end">
                            <button className="btn btn-outline-secondary btn-sm mb-2" onClick={handleToggleCreate}>Skapa ny bevakningskund</button>
                        </div>
                    </div>
                }
                <div>
                    {prioritizedCustomers?.map(prioritizedCustomer => <PrioritizedCustomerComponent prioritizedCustomer={prioritizedCustomer} districts={districts} onDelete={handleDelete} key={prioritizedCustomer.customerId} />)}
                </div>
            </>
        );
    }
    else if (displaySettings.isAuthenticated) {
        return (
            <div className="text-center">
                Ni har inte rättighet att använda den här applikationen. För att få tillgång, kontakta MKBs uthyrningschef
            </div>
        );
    }
    else {
        return (<></>)
    }
}

export default PrioritizedCustomersComponent;