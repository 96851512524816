import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButtonComponent } from "../authentication/components/SignInButtonComponent";
import { SignOutButtonComponent } from "../authentication/components/SignOutButtonComponent";
import logo from "../assets/images/mkblogo-white.png";
import "../styles/Header.css"

const PublicLayout = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = useIsAuthenticated();
  const apiUrl = process.env.REACT_APP_MSAL_REDIRECT_URL;

  return (
   <>
     <header
        className="navbar align-items-center text-white header-background"
      >
        <div className="container-fluid w-50 d-flex flex-column flex-md-row p-4">
          <div className="col-4 col-md-2 mb-3 mb-md-0">
            <a href={apiUrl}>
              <img
                src={logo}
                alt="MKB Logo"
                className="img-fluid"
                style={{ maxHeight: "50px" }}
              />
            </a>
          </div>
          <div className="col-8 col-md-8 text-center mb-3 mb-md-0 d-flex justify-content-center">
            <h3 className="h3">Bevakningskunder</h3>
          </div>
          <div className="col-12 col-md-2 text-nowrap text-center text-md-end">
            {isAuthenticated ? (
              <SignOutButtonComponent />
            ) : (
              <SignInButtonComponent />
            )}
          </div>
        </div>
      </header>
      <div className="container w-50 mt-5">
        <div className="row">
          {children}
        </div>
      </div>
      </>
  );
};

export default PublicLayout;
