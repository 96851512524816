import React from 'react';
import './App.css';
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
import PublicLayout from './layouts/PublicLayout';
import PrioritizedCustomersComponent from './components/PrioritizedCustomersComponent';

function App() {
    return (
        <div className="app-container">
            <BrowserRouter>
                <PublicLayout>
                    <Routes>
                        <Route path="/" element={<PrioritizedCustomersComponent />} />
                    </Routes>
                </PublicLayout>
          </BrowserRouter>
        </div>
    );
}

export default App;
