import { District, PrioritizedCustomer } from '../models/PrioritizedCustomerModels';
import BaseClient from './BaseClient';

export async function getPrioritizedCustomers(): Promise<{ prioritizedCustomers: PrioritizedCustomer[], hasError: boolean }> {
    try {
        const data = await BaseClient.get('prioritizedcustomer').then((response) => {
            if (response.data && response.data.length > 0) {

                var prioritizedCustomers = response.data as PrioritizedCustomer[];

                prioritizedCustomers.forEach((item) => {
                    item.queueStart = new Date(item.queueStart);
                });

                return { prioritizedCustomers: prioritizedCustomers, hasError: false };
            }
            else {
                return { prioritizedCustomers: [] as PrioritizedCustomer[], hasError: false };
            }
        });

        return data;
    } catch (error) {
        return { prioritizedCustomers: [] as PrioritizedCustomer[], hasError: true };
    }
}

export async function putPrioritizedCustomers(prioritizedCustomer: PrioritizedCustomer): Promise<{ hasError: boolean }> {
    try {
        const data = await BaseClient.put('prioritizedCustomer', prioritizedCustomer).then((response) => {
            if (response.status === 200) {
                return { hasError: false };
            }
            else {
                return { hasError: true };
            }
        });

        return data;
    } catch (error) {
        return { hasError: true };
    }
}

export async function deletePrioritizedCustomer(customerId: string): Promise<{ hasError: boolean }> {
    try {
        const data = await BaseClient.delete(`prioritizedCustomer/${customerId}`).then((response) => {
            if (response.status === 200) {
                return { hasError: false };
            }
            else {
                return { hasError: true };
            }
        });

        return data;
    } catch (error) {
        return { hasError: true };
    }
}

export async function getDistricts(): Promise<{ districts: District[], hasError: boolean }> {
    try {
        const data = await BaseClient.get('prioritizedCustomerDistrict').then((response) => {
            if (response.data && response.data.length > 0) {

                var prioritizedCustomers = response.data as District[];

                return { districts: prioritizedCustomers, hasError: false };
            }
            else {
                return { districts: [] as District[], hasError: false };
            }
        });

        return data;
    } catch (error) {
        return { districts: [] as District[], hasError: true };
    }
}

