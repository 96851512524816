import React, { useCallback, useEffect, useState } from 'react';
import { District, PrioritizedCustomer } from '../models/PrioritizedCustomerModels';
import DistrictComponent from './DistrictComponent';
import Moment from 'moment';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { deletePrioritizedCustomer, putPrioritizedCustomers } from '../services/PrioritizedCustomerService';
import Select, { MultiValue } from 'react-select';

interface DisplaySettings {
    editPrioritizedCustomer: boolean
    showErrorInformation: boolean
    showDateTimeValidationError: boolean
    showLivingAreaValidationError: boolean
    showMinNrRoomsValidationError: boolean
    showMaxNrRoomsValidationError: boolean
    showMaxRentValidationError: boolean
    savingPrioritizedCustomer: boolean
    deletePrioritizedCustomer: boolean
    showSavingPrioritizedCustomerError: boolean
    showDeletePrioritizedCustomerError: boolean
}

function PrioritizedCustomerComponent({ prioritizedCustomer, districts, onDelete }: { prioritizedCustomer: PrioritizedCustomer, districts: District[], onDelete: (customerId: string) => void}) {
    const numberPattern = /^\d*$/;

    const [currentPrioritizedCustomer, setPrioritizedCustomer] = useState<PrioritizedCustomer>({ customerId:'', districts:[] as District[],freeText:'',livingArea:0,maxNrRooms:0,maxRent:0,minNrRooms:0,queueStart:new Date(), queueStartString:'' } as PrioritizedCustomer);
    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({ editPrioritizedCustomer: false } as DisplaySettings);

    useEffect(() => {
        ResetPrioritizedCustomer();
    }, []);

    const ResetPrioritizedCustomer = () => {
        setPrioritizedCustomer({
            customerId: prioritizedCustomer.customerId,
            districts: prioritizedCustomer.districts.map<District>((district) => ({ district: district.district })),
            freeText: prioritizedCustomer.freeText,
            livingArea: prioritizedCustomer.livingArea,
            maxNrRooms: prioritizedCustomer.maxNrRooms,
            maxRent: prioritizedCustomer.maxRent,
            minNrRooms: prioritizedCustomer.minNrRooms,
            queueStart: prioritizedCustomer.queueStart,
            queueStartString: `${prioritizedCustomer.queueStart.getFullYear()}-${String(prioritizedCustomer.queueStart.getMonth() + 1).padStart(2, '0')}-${String(prioritizedCustomer.queueStart.getDate()).padStart(2, '0') }`,
        } as PrioritizedCustomer);
    }

    function toggleEditPrioritizedCustomer() {
        if (displaySettings.editPrioritizedCustomer) {
            ResetPrioritizedCustomer();
            setDisplaySettings((prevState) => ({ ...prevState, editPrioritizedCustomer: false, showSavingPrioritizedCustomerError: false }));
        }
        else {
            setDisplaySettings((prevState) => ({ ...prevState, editPrioritizedCustomer: true }));
        }
    }

    function SavePrioritizedCustomer() {
        setDisplaySettings((prevState) => ({ ...prevState, savingPrioritizedCustomer: true, showSavingPrioritizedCustomerError: false, showDeletePrioritizedCustomerError:false }));

        putPrioritizedCustomers(currentPrioritizedCustomer).then(response => {
            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showSavingPrioritizedCustomerError: true }));
            }
            else {
                setDisplaySettings((prevState) => ({ ...prevState, editPrioritizedCustomer: false }));
            }
        }).finally(() => {
            setDisplaySettings((prevState) => ({ ...prevState, savingPrioritizedCustomer: false }));
        })
    }

    function DeletePriortizedCustomer() {
        setDisplaySettings((prevState) => ({ ...prevState, deletePrioritizedCustomer: true, showDeletePrioritizedCustomerError: false, showSavingPrioritizedCustomerError: false }));

        deletePrioritizedCustomer(currentPrioritizedCustomer.customerId).then(response => {
            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showDeletePrioritizedCustomerError: true }));
            }
            else {
                onDelete(currentPrioritizedCustomer.customerId);
            }
        }).finally(() => {
            setDisplaySettings((prevState) => ({ ...prevState, deletePrioritizedCustomer: false }));
        })
    }

    const ToggleEditPrioritizedCustomerIcon = () => {

        if (displaySettings.editPrioritizedCustomer) {
            return (<span role="button"><i className="fa-solid fa-xmark ms-2" onClick={toggleEditPrioritizedCustomer}></i></span>)
        }
        else {
            return (<span role="button"><i className="fa-solid fa-pen-to-square ms-2" onClick={toggleEditPrioritizedCustomer}></i></span>)
        }
    }

    const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setDisplaySettings((prevState) => ({ ...prevState, showDateTimeValidationError: false }));

        const pattern = /^\d{4}-\d{2}-\d{2}$/;
        var date = Moment(event.target.value, 'YYYY-MM-DD');

        if (!date.isValid() || !pattern.test(event.target.value)) {
            setDisplaySettings((prevState) => ({ ...prevState, showDateTimeValidationError: true }));
        }
        else {
            setPrioritizedCustomer((prevState) => ({ ...prevState, queueStart: new Date(event.target.value) }))
        }

        setPrioritizedCustomer((prevState) => ({ ...prevState, queueStartString: event.target.value }))
    };

    const handleLivingAreaChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value === '') {
            setPrioritizedCustomer((prevState) => ({ ...prevState, livingArea: 0 }))
        }
        else if (numberPattern.test(event.target.value)) {
            setPrioritizedCustomer((prevState) => ({ ...prevState, livingArea: parseInt(event.target.value) }))
        }
    };

    const handleMinNrRoomsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value === '') {
            setPrioritizedCustomer((prevState) => ({ ...prevState, minNrRooms: 0 }))
        }
        else if (numberPattern.test(event.target.value)) {
            setPrioritizedCustomer((prevState) => ({ ...prevState, minNrRooms: parseInt(event.target.value) }))
        }
    };

    const handleMaxNrRoomsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value === '') {
            setPrioritizedCustomer((prevState) => ({ ...prevState, maxNrRooms: 0 }))
        }
        else if (numberPattern.test(event.target.value)) {
            setPrioritizedCustomer((prevState) => ({ ...prevState, maxNrRooms: parseInt(event.target.value) }))
        }
    };

    const handleMaxRentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value === '') {
            setPrioritizedCustomer((prevState) => ({ ...prevState, maxRent: 0 }))
        }
        else if (numberPattern.test(event.target.value)) {
            setPrioritizedCustomer((prevState) => ({ ...prevState, maxRent: parseInt(event.target.value) }))
        }
    };

    const handleDistrictChange = (value: MultiValue<District>) => {
        console.log("Value", value as District[]);
        setPrioritizedCustomer((prevState) => ({ ...prevState, districts: value as District[] }))
    }

    const RemovePrioritizedCustomerIcon = ({ customerNumber }: { customerNumber: string }) => {
        if (displaySettings.editPrioritizedCustomer) {
            return (<></>);
        }
        else {
            return (<>
                <OverlayTrigger trigger="click" rootClose placement="left" overlay={popoverServiceReport(customerNumber)}>
                    <span role="button"><i className="fa-solid fa-trash ms-2" /></span>
                </OverlayTrigger>
            </>)
        }
    }

    const popoverServiceReport = (customerNumber: string) => {
        return (
            <Popover className="popover-size-4">
                <h3 className="popover-header">Vill du ta bort bevakningskund: {customerNumber}</h3>

                <div className="p-3 mb-2 d-grid gap-2 d-flex justify-content-center">
                    <button className="btn btn-primary btn-sm  ps-4 pe-4" onClick={DeletePriortizedCustomer}>Ta bort</button>
                    <button className="btn btn-secondary btn-sm ps-4 pe-4" type="button" onClick={() => document.body.click()}>Avbryt</button>
                </div>
            </Popover>
        )
    };

    return (<>
        <div className="card mb-5">
            <div className="card-body">

                <div className="row">
                    <div className="col-8">
                        <h5 className="card-title">Kundnummer: {currentPrioritizedCustomer?.customerId}</h5>
                    </div>
                    <div className="col-4 text-end">
                        {displaySettings.deletePrioritizedCustomer ?
                            <>
                                <div className="d-flex justify-content-end text-secondary">
                                    <div className="pe-2 fw-semibold">Deleting</div>
                                    <div className="spinner-container ">
                                        <div className="spinner-border spinner-border-sm" role="status"><span className="visually-hidden"></span> </div>
                                    </div>
                                </div>
                            </> :
                            <>
                                <ToggleEditPrioritizedCustomerIcon />
                                <RemovePrioritizedCustomerIcon customerNumber={currentPrioritizedCustomer.customerId} />
                            </>
                        }
                    </div>
                </div>

                {displaySettings.editPrioritizedCustomer ?
                    <div className="mb-2">
                        <Select
                            isMulti
                            name="colors"
                            value={currentPrioritizedCustomer.districts}
                            options={districts}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            getOptionLabel={(option) => option.district}
                            getOptionValue={(option) => option.district}
                            onChange={handleDistrictChange}
                            isDisabled={displaySettings.savingPrioritizedCustomer}
                        />
                    </div> :
                    <h6 className="card-subtitle mb-2 text-muted"><DistrictComponent districts={currentPrioritizedCustomer?.districts}></DistrictComponent></h6>
                }

                <div className="row">
                    <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="row">
                            <div className="col-4">Rum:</div>
                            {displaySettings.editPrioritizedCustomer ?
                                <>
                                    <div className="col-2 mb-1"><input type="text" className={displaySettings.showMinNrRoomsValidationError ? 'border-danger form-control form-control-sm' : 'form-control form-control-sm'} value={currentPrioritizedCustomer?.minNrRooms} onChange={handleMinNrRoomsChange} disabled={displaySettings.savingPrioritizedCustomer} /></div>
                                    <div className="col-1 pt-1">-</div>
                                    <div className="col-2"><input type="text" className={displaySettings.showMaxNrRoomsValidationError ? 'border-danger form-control form-control-sm' : 'form-control form-control-sm'} value={currentPrioritizedCustomer?.maxNrRooms} onChange={handleMaxNrRoomsChange} disabled={displaySettings.savingPrioritizedCustomer} /></div>
                                </> :
                                <div className="col-8">{currentPrioritizedCustomer?.minNrRooms}-{currentPrioritizedCustomer?.maxNrRooms}</div>
                            }
                        </div>

                        <div className="row">
                            <div className="col-4">Min area:</div>
                            {displaySettings.editPrioritizedCustomer ?
                                <div className="col-5 mb-1"><input type="text" className={displaySettings.showLivingAreaValidationError ? 'border-danger form-control form-control-sm' : 'form-control form-control-sm'} value={currentPrioritizedCustomer?.livingArea} onChange={handleLivingAreaChange} disabled={displaySettings.savingPrioritizedCustomer} /></div> :
                                <div className="col-8">{currentPrioritizedCustomer?.livingArea} m<sup>2</sup></div>
                            }
                        </div>

                        <div className="row">
                            <div className="col-4">Max hyra:</div>
                            {displaySettings.editPrioritizedCustomer ?
                                <div className="col-5 mb-1"><input type="text" className={displaySettings.showMaxRentValidationError ? 'border-danger form-control form-control-sm' : 'form-control form-control-sm'} value={currentPrioritizedCustomer?.maxRent} onChange={handleMaxRentChange} disabled={displaySettings.savingPrioritizedCustomer} /></div> :
                                <div className="col-8">{currentPrioritizedCustomer?.maxRent} kr</div>
                            }
                        </div>

                        <div className="row">
                            <div className="col-4">Registreringsdatum:</div>
                            {displaySettings.editPrioritizedCustomer ?
                                <div className="col-5 mb-1"><input type="text" className={displaySettings.showDateTimeValidationError ? 'border-danger form-control form-control-sm' : 'form-control form-control-sm'} value={currentPrioritizedCustomer?.queueStartString} onChange={handleDateTimeChange} disabled={displaySettings.savingPrioritizedCustomer} placeholder="YYYY-MM-DD" /></div> :
                                <div className="col-8">{currentPrioritizedCustomer?.queueStartString}</div>
                            }
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                        {displaySettings.editPrioritizedCustomer ?
                            <textarea className="form-control form-control-sm" rows={5} value={currentPrioritizedCustomer.freeText} onChange={(e) => setPrioritizedCustomer((prevState) => ({ ...prevState, freeText: e.target.value }))} disabled={displaySettings.savingPrioritizedCustomer} /> :
                            <div className="col-12">{currentPrioritizedCustomer?.freeText}</div>
                        }
                    </div>
                    {displaySettings.showSavingPrioritizedCustomerError ?
                        <div className="col-12 text-end text-danger">
                            Något gick fel när bevakningskunden skulle sparas, försök gärna igen.
                        </div> : 
                        <></>
                    }

                    {displaySettings.showDeletePrioritizedCustomerError ?
                        <div className="text-danger">
                            Något gick fel när bevakningskunden skulle tas bort, försök gärna igen!
                        </div> :
                        <></>
                    }


                    {displaySettings.editPrioritizedCustomer ?
                        <div className="col-12 text-end">
                            <button className="btn btn-primary btn-sm float-end d-flex" disabled={displaySettings.showDateTimeValidationError || displaySettings.savingPrioritizedCustomer} onClick={SavePrioritizedCustomer}>
                                {displaySettings.savingPrioritizedCustomer ?
                                    <>
                                        <div className="spinner-container pe-2">
                                            <div className="spinner-border spinner-border-sm" role="status"><span className="visually-hidden"></span> </div>
                                        </div> Sparar
                                    </> :
                                    <>Spara</>
                                }
                            </button>
                        </div> :
                        <></>
                    }
                </div>
            </div>
        </div>
    </>);
}

export default PrioritizedCustomerComponent;