import React from 'react';
import { District } from '../models/PrioritizedCustomerModels';

function DistrictComponent({ districts }: { districts : District[] | undefined}) {
  return (
      <>
          Önskat område: {districts?.map((district, i) => <span key={i}>{i > 0 ? ', ' : ''}{district.district}</span>)}
      </>
  );
}

export default DistrictComponent;